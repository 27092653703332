<template>
<div class="alarm-rule-container">
  <!-- <div class="query-data-area">
    <AuthButton opCode='lgd' size="default" type="error" @click="deleteParams" style="margin: 0 5px;">{{showLang('com.op.batch.del')}}</AuthButton>
  </div> -->
  <div class="alarm-data-area" ref="table1">
    <vxe-table ref="chkTable" border show-overflow show-header-overflow :data="list" height="auto" :row-config="{isHover: true}">
      <vxe-column type="seq" width="60" ></vxe-column>
      <vxe-column field="name" :title="showLang('com.tab.title')" width="120" header-align="center"></vxe-column>
      <vxe-column field="code" :title="showLang('com.group.number')" header-align="center"></vxe-column>
      <vxe-column width="220" title="操作" fixed="right">
        <template #header>
          <Button v-if='funCodes(2041)' size="small" type="primary" style="margin-right: 5px" @click="handleAdd">{{showLang('com.op.add')}}</Button>
          <Button v-if='funCodes(2040)' size="small" type="primary" style="margin-right: 5px" @click="getList">{{showLang('com.op.refresh')}}</Button>
        </template>
        <template #default="params">
          <Button v-if='funCodes(2042)' size="small" type="primary" style="margin-right: 5px" @click="handleEdit(params)">{{showLang('com.op.edit')}}</Button>
          <Button v-if='funCodes(2043)' size="small" type="error" style="margin-right: 5px" @click="handleDelete(params)">{{showLang('com.op.del')}}</Button>
        </template>
      </vxe-column>
    </vxe-table>
    <ModalAreaEdit v-model="showEditModal" :item="editItem" :stations="selectedStations" @saved="itemSaved" />
    <!-- <ModalArgEdit v-model="showArgModal" /> -->
  </div>
</div>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
import ModalAreaEdit from './ModalAreaEdit'
// import ModalArgEdit from './ModalArgEdit'
export default {
  name: 'ConfigAreaIndex',
  components:{
    ModalAreaEdit,
    // ModalArgEdit,
  },
  props: {
  },
  data () {
    return {
      curTab: 'hour24',
      list: [],
      editItem: {isAdd: true, data: {}},
      showEditModal: false,
      showArgModal: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang', 'funCodes']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    selectedStations: function(){
      return this.selectedDevices.filter(p => p.type == this.productCodes.station);
    },
    selectedStationIds: function(){
      let pids = this.selectedDevices.filter(p => p.type == this.productCodes.station).map(p => {return p.id});
      return pids;
    },
  },
  watch: {
  },
  mounted: function(){
    this.getList();
  },
  destroyed: function(){
  },
  methods: {
    handleAdd: function(){
      this.editItem = {isAdd: true, data: {}};
      this.showEditModal = true;
    },
    handleEdit: function(params){
      this.editItem = {isAdd: false, data: params.row};
      this.showEditModal = true;
    },
    itemSaved: function(){
      this.getList();
    },
    deleteParams: function(){
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if(chks.length == 0){
        this.$Message.warning(this.showLang('com.tips.sel.del.record'));
        return;
      }
      let list = chks.map(p => p.id);
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.record'),
        onOk: async () => {
          this.$axios.post(`device/light/DeleteGroup`, {list: list}).then(res => {
            if(res.code == 0){
              this.getList();
            }
          });
        }
      });
    },
    handleDelete: function(params){
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.name',params.row.name),
        onOk: async () => {
          this.$axios.post(`device/light/DeleteGroup`, {list: [params.row.id]}).then(res => {
            if(res.code == 0){
              this.getList();
            }
          });
        }
      });
    },
    getList: function(){
      this.$axios.post(`device/light/QueryGroup`, {}).then(res => {
        if(res.code == 0){
          this.$set(this, 'list', res.data);
        }
      });
    },
  }
}
</script>
<style scoped>
.alarm-rule-container{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* padding: 10px; */
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.query-data-area{
  height: 45px;
  flex: none;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.alarm-data-area{
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  /* padding-left: 10px; */
}
.station-alarm-tabs{
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab{
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active{
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent{
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>