<template>
  <Modal v-model="showModal" :mask-closable="false" width="500px">
    <div slot="header">{{showLang('com.edit.light.group')}}</div>
    <Form ref="form" :model="form" :rules="rules" :label-width="110">
      <FormItem prop="name" :label="showLang('com.tab.title')" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.name" :placeholder="showLang('com.tab.title')"></Input>
        </div>
      </FormItem>
      <FormItem prop="code" :label="showLang('com.group.number')" style="margin-right: 15px">
        <Input type="number" number v-model="form.code">
          <span slot="append">1 - 255 {{showLang('com.tips.between')}}</span>
        </Input>
      </FormItem>
      <!-- <FormItem prop="bright" label="默认亮度" style="margin-right: 15px">
        <Input type="number" number v-model="form.bright">
          <span slot="append">0 - 100 之间</span>
        </Input>
      </FormItem>
      <FormItem prop="color" label="默认色温" style="margin-right: 15px">
        <Input type="number" number v-model="form.color">
          <span slot="append">0 - 100 之间</span>
        </Input>
      </FormItem> -->
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
    <!-- <ModalSelectGroup v-model="showSelectModal" @newGroupSelected="newGroupSelected" /> -->
  </Modal>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
export default {
  name: 'ModalAreaEdit',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
    stations: {
      type: Array,
      default(){ return [];}
    },
  },
  data () {
    return {
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
      isAdd: false,
      types: [],
      groupName: '',
      mains: [],
      devices: [],
      form: {
        id: 0,
        code: '',
        name: '',
      },
      rules: {
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        // console.log('modal level edit mounted', this.item)
        this.isAdd = this.item.isAdd;
        if(!this.isAdd){
          this.form.id = this.item.data.id;
          this.form.name = this.item.data.name;
          this.form.code = this.item.data.code;
        }else{
          this.form.id = 0;
          this.form.name = '';
          this.form.code = 1;
        }
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('common', ['weeks', 'deviceTypes', 'productCodes', 'powerBoxTypes']),
  },
  mounted: function(){
  },
  methods: {
    ok: async function () {
      if(this.form.name.trim().length == 0){
        this.$Message.warning(this.showLang('save.name'));
        return;
      }
      if(this.form.code <= 0 || this.form.code > 255){
        this.$Message.warning(this.showLang('com.tips.group.255between'));
        return;
      }
      this.$axios.post(`device/light/SaveGroup`, this.form).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$Message.info(this.showLang('com.tips.save'));
          this.showModal = false;
          this.$emit('saved', {isAdd: this.isAdd, data: res.data});
        }
      })
    },
    cancel () {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.form-item-group{
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header{
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
  .demo-drawer-footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
  }
  .form{
    height: calc(100% - 54px);
    overflow: auto;
  }
  .readonly{
    background: #eee
  }
</style>